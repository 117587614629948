@media screen and (max-width:550px) {

    .side-popup>.child {
        max-width: 90vw !important;
    }

    .tab-content .content .flex {
        grid-template-columns: 1fr auto !important;
    }

    .container>.flex {
        grid-template-columns: 1fr;
    }
}

// HOPE YOU ARE HAPPY NOW
@media screen and (max-width:650px) {
    .side-popup>.child>section {
        padding-bottom: 50px !important;
    }

    .popup>.child {
        max-height: 80dvh !important;
        margin-bottom: 6vh !important;
    }
}