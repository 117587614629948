.chat-body {
  height: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
  align-content: baseline;
  margin-top: 11px;
  .chat-performance {
    text-align: center;
    padding: 12px;
    min-height: 50px;
    display: grid;
    justify-content: center;
    align-items: center;

    .performance-metrics {
      b {
        padding: 2px 5px;
      }
    }
  }
}

.chat-window {
  border: 1px solid #ccc;
  border-radius: 4px;
  display: grid;
  grid-template-columns: 300px 1fr;
  flex-direction: row;
  width: 100%;
  min-width: 90vw;
  max-width: 1200px;
  box-shadow: 0 6px 18px #00000025;

  &.none {
    display: flex;
    padding: 10px;
    margin: 10px;
    text-align: center;
    justify-content: center;
    align-items: center;
    min-height: 80dvh;
    text-transform: capitalize;
    color: #bdbdbd;
    font-weight: 500;
    letter-spacing: 0.2px;
    font-size: calc(1vw + 10px);

  }
}

.conversations {
  overflow-y: scroll;
  flex: 1;
  // background-color: rgb(46, 44, 44);
  display: flex;
  flex-direction: column;
  height: calc(65vh + 100px);
}

div.templateMsg {
  background: #dadada50 !important;

}

.message-area {
  display: grid;
  grid-template-rows: 50px 1fr auto;
  width: 100%;
  height: calc(65vh + 100px);

  header {
    background-color: #f0f2f5;
    border-bottom: 1px solid #dadada;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 14px;

    span {
      font-size: 17px;
      padding: 5px 10px;

      i {
        transform: rotate(90deg);
      }

      position: relative;
      color: #444444;
      cursor: pointer;

      section {
        z-index: 1;
        white-space: nowrap;
        right: 0;
        position: absolute;
        width: 0;
        height: 0;
        display: none;
        background: #fff;
        border: 1px solid #dadada;
        border-radius: 4px;

        >div {
          font-size: 14px;

          &:hover {
            background: #c0faba !important;
          }

          padding: 5px 16px;
        }
      }

      &:hover {
        section {
          width: unset;
          height: unset;
          display: block;
        }
      }
    }
  }

  .messages-container {
    background-color: #efeae2;
    // max-height: 70dvh;
    overflow-y: scroll;

    img {
      position: relative;
      max-width: 100%;
      cursor: pointer;
    }
  }

  .singleMessage {
    color: #fff;
    padding-left: 20px;
    padding-bottom: 5px;
  }

  .messages {
    text-align: left;
    margin-bottom: 0px;
    padding: 2px;
    max-width: fit-content;
    border-radius: 5%;
    background-color: #444444;
    margin-left: auto;
    margin-right: 10px;
    padding: 10px 16px;
    margin-top: 4px;
    border-radius: 10px 0 10px 10px;
    line-height: 1;
    display: grid;


    img::after {
      content: "Tap To Load";
      top: 0;
      left: 0;
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      background-color: #67a798;
      background-size: contain;
      background-position: center;
      color: #dbf9f2;
      display: grid;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      font-size: 14px;
    }

    .time {
      font-size: 10px;
      padding: 0 2px;
      opacity: 0.6;
      margin-bottom: -5px;
      text-align: end;
      padding: 4px 0 0;

      >span>i:nth-child(2) {
        margin-left: -10px;
      }

      .status {
        padding: 0 5px;
        font-size: 15px;

        &[readstatus="read"] {
          color: #0cd942;
        }
      }
    }

    &.admin {
      text-align: right;
      background-color: #26997d;
      border-radius: 0 10px 10px 10px;
      margin-right: auto;
      margin-left: 0;

      .time {
        text-align: start;
      }
    }
  }
}

.messagesForm {
  margin-top: auto;
  order: 999;
  position: relative;
  padding: 0px 10px 0px 10px;
  // margin-bottom: 25px;
  margin: 0 !important;

  >section {
    height: 30px;
    overflow: hidden;
    overflow-x: auto;
    display: flex;
    max-width: calc(100vw - 120px);
    gap: 5px;

    >div {
      white-space: nowrap;
      line-height: 1;
      display: grid;
      justify-content: center;
      align-items: center;
      padding: 0 10px;
      cursor: pointer;
      background-color: #fff;
      color: #111;
      border-radius: 5px;
    }


    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    &::-webkit-scrollbar-track {
      background: rgb(36, 35, 35);
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
    }


  }

  >button {
    padding: 2px;
    border: 1px solid #fff;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgb(131, 128, 128);

    position: absolute;
    right: 25px;
    background: transparent;
    border: unset;
    color: #0e63a3;
    font-size: 23px;
    top: 5px;
  }
}

.message {
  width: calc(100% - 14px);
  box-sizing: border-box;
  padding: 9px 18px 11px;
  margin: 5px 7px 19px;
  background-color: rgb(255 255 255);
  border: 1px solid #a9daff;
  border-radius: 5px;
  padding-right: 50px;
  color: black;
  resize: none;
  overflow-y: hidden;
  height: 48px;
  min-height: 48px;
  /* Set a minimum height to avoid a very small textarea */

}

.chat-details {
  display: grid;
  grid-template-columns: 1fr auto;
  border-bottom: 1px solid rgb(210, 210, 210);
  cursor: pointer;
  padding: 10px;

  .time {
    font-size: 13px;
  }

  &.active {
    background-color: #d2d2d2;
  }
}

.userImage {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgb(161, 154, 154);
  display: flex;
  justify-content: center;
  align-items: center;
}

.message-content {
  // margin-top: 2px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 0;
  overflow: hidden;
  max-width: 100px;
  max-height: 50px;

  >p {

    margin-bottom: 0px;
    margin-block-start: 0px;
    margin-block-end: 0px;
  }

}

@media (max-width: 968px) {

  .chat-window {
    min-width: 100vw;
    height: 100%;
  }

  .message-area,
  .conversations {
    height: calc(100dvh - 69px);
  }
}

@media (max-width: 768px) {
  .message-content {
    min-width: 50px;
    max-width: 50px;
  }

  .chat-window {
    min-height: 75vh;
  }

  .chat-details .time {
    display: none;
  }

  .chat-window {
    grid-template-columns: 120px 1fr;
  }
}




.conversations::-webkit-scrollbar,
.messages-container::-webkit-scrollbar {
  width: 7px;
}

.conversations::-webkit-scrollbar-track,
.messages-container::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0);
}


.messages-container::-webkit-scrollbar-thumb,
.conversations::-webkit-scrollbar-thumb {
  background: #c2c2c2;
  border-radius: 8px;
  margin: 1px;
}