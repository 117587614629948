@import './colors.scss';

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.hide {
    display: none;
    opacity: 0;
}

.nowrap {
    white-space: nowrap;
}

.a {
    color: #0d6efd !important;
    cursor: pointer;
    background-color: unset !important;
    border: unset !important;
    text-decoration: underline;

    &:disabled {
        color: gray !important;
        background-color: transparent !important;
        cursor: not-allowed;
    }

    &.whatsapp {
        color: #00a884 !important;
        padding: 2px 4px;
        line-height: 1;
    }
}

.modal.hide {
    display: none !important;
}

.modal.show {
    position: absolute;
    background: #00000020;
    left: 0;
    right: 0;
    top: 0;
    overflow: auto;
    max-height: 100dvh;

    .modal-dialog {

        width: 700px;
        max-width: 80vw;
        margin: 42px auto;
        background: #fff;
        padding: 10px;
        border-radius: 5px;

        h4 {
            display: inline;
            text-decoration: underline;
        }

        .card {
            background: #dadada30;
            padding: 10px;
            margin-bottom: 7px;
            border: 1px solid #dadada80;
            border-radius: 4px;
        }
    }

}

.lang-select {
    display: grid;
    grid-template-columns: 80px 140px;
    justify-content: center;
    align-items: center;

    select {
        margin-bottom: 0px !important;
    }
}

.head-container {
    background: #dadada59;
    margin: 0;
    border-bottom: 1px solid #dadada;
    margin-bottom: 14px;
    text-align: center;

    .headerLogo {
        max-width: 140px;
    }

    nav {
        display: flex;
        justify-content: space-between;
    }
}

.form-g50 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.sigCanvas.self {
    max-width: calc(100vw - 40px);
}

@media screen and (max-width:430px) {
    .head-container nav {
        display: grid;
        align-self: center;
        align-items: center;
        align-content: center;
        text-align: center;
        justify-content: center;

        img {
            margin: 0 auto 14px;
        }
    }

    .form-g50 {
        grid-template-columns: 1fr;
    }

}

.center-em {

    min-width: 100vw;
    min-height: 100dvh;
    justify-content: center;
    align-items: center;
    display: grid;
    padding: 0;
    margin: 0;

}

.d-center {
    text-align: center;
    min-height: calc(100dvh - 200px);
    display: grid;
    align-items: center;
    align-content: center;
}

.phoneWrap {
    display: grid;
    grid-template-columns: 67px 1fr;

    .custom-select {
        border-radius: 4px 0 0 4px;
    }

    .form-control {
        border-radius: 0 4px 4px 0;
    }
}

textarea {
    resize: none;
}


.sm-ps.card-image {
    max-height: 250px;
    width: 100%;
    object-fit: cover;
    border: 1px solid #dadada;
}

.modal-body {
    ul {
        margin-bottom: 5px;

        li {
            display: flex;
            padding: 0px 0px 0px 10px;
            font-size: 14px;

            &::before {
                padding: 0 3px;
                content: "-";
            }
        }
    }

    .alert.alert-warning {
        padding: 10px;
        font-size: 14px;
        background: #fff4cf;
        margin-bottom: 10px;
        border-radius: 5px;
        color: #4b4400;
        text-align: center;
        line-height: 1.1;
    }
}

// .card {
//     background-color: transparent;
//     padding: 10px;
//     margin: 10px;

//     &.gray {
//         margin: 0;
//         padding: 2px;
//         border: 1px solid #dadada;
//         background-color: #fafafa !important;
//     }
// }

// .grid {
//     display: grid;
// }



// .flex {
//     display: flex;
//     gap: 10px;
//     justify-content: center;
//     align-items: center;

//     &.space {
//         display: grid;
//         grid-template-columns: 1fr 1fr;
//         text-align: start;
//     }

//     &.three {
//         grid-template-columns: 1fr 1fr 1fr;
//     }

// }

// .sm-ps {
//     width: 100%;
//     max-height: 300px;
//     object-position: center;
//     object-fit: contain;
// }

// .center {
//     text-align: center;
// }

// .card-body.main {
//     text-align: center;
// }

// .card-body.main,
// body,
// html {
//     background-color: #333;
// }

// .left {
//     text-align: start;
// }



// body,
// html {
//     min-width: 100%;
//     min-height: 100dvh;
// }

// h1,
// h2,
// h3,
// h4,
// h5,
// h6,
// p {
//     text-align: center;
// }

// hr {

//     margin: 10px 0;
//     background-color: #adadad;
//     border: unset;
//     height: 4px;
//     opacity: 0.2;
// }

// .link {
//     cursor: pointer;
//     color: #252e59;
//     text-decoration: underline;
// }

// .card-body.main>img {
//     max-width: 80px;
//     margin: 0 auto 30px;
// }

// .flex.center {
//     justify-content: center;
//     align-items: center;

//     .btn {
//         margin: 0;
//     }
// }

// .a {
//     color: #434e85;
//     cursor: pointer;
//     text-decoration: underline;
// }

// .card.dark {
//     position: relative;

//     >b.a {
//         position: absolute;
//         top: 0;
//         right: 10px;

//     }
// }




// .warn {
//     padding: 30px;
//     border: 1px solid #dadada50;
//     border-radius: 5px;
//     background-color: #434e85;
// }

// .card-sm {
//     border: 2px solid #dadada10;
//     background-color: #ffffff10;
//     padding: 10px;
//     margin: 10px;
// }

// .hide {
//     display: none;
// }



// .modal.show {
//     display: block;

//     .card {
//         color: #252e59 !important;
//     }

//     color: #252e59 !important;

//     .modal-dialog {
//         width: 100%;
//         max-width: 80%;
//         margin: 0 auto;
//     }
// }

// .anchour {
//     cursor: pointer;
//     text-decoration: underline;
//     color: #3f9cc1;
// }


// nav {
//     padding: 30px;
//     background: #dadada10;
// }

// .btn-c {
//     text-align: center;

//     .btn.btn-secondary.main,
//     .btn.btn-primary.main {
//         width: 100%;
//         max-width: 290px;
//         padding: 14px;
//         margin: 0 auto;
//         border-radius: 5px !important;
//         font-size: 19px;
//     }
// }



// .main-container {
//     display: grid;
//     padding: 30px 10px;

//     >nav {
//         padding: 10px;
//         border: 1px solid #dadada;

//         a {
//             display: block;
//             padding: 10px;
//             margin: 10px;
//         }
//     }
// }

// .Signature {
//     canvas {
//         border: 1px solid #444;
//         background-color: #fff;
//     }
// }

// .form-group {
//     margin-bottom: 10px;

//     >label {
//         max-width: 100%;
//         width: 100%;
//         text-align: start;
//     }
// }

// .form-select,
// .form-control {
//     background-color: #ffffffde;
// }

// .headerLogo {
//     max-width: 100px;
// }

// .flex-print {
//     text-align: center;
//     display: grid;
//     justify-content: center;
//     align-items: center;
//     margin: 0 auto;

//     img {
//         align-items: center;
//         margin: 0 auto;
//     }
// }

// .no-btn {
//     background-color: transparent;
//     border: unset;
//     padding: 0;
//     margin: 0;

//     &.h {
//         color: #0d6efd;
//     }

//     &.done {
//         color: #07aa81;
//         cursor: not-allowed;
//     }
// }

// .dark-container {
//     background-color: #333;
//     padding-top: 20px;
// }


.price-container {
    text-align: center;
    margin-top: 25px;

    label {
        text-align: start;
        display: block;
    }

    button {
        margin: 0 auto;
    }

    p {
        text-align: start;
    }

    input:disabled {
        background-color: #bbbbbb;
        opacity: 0.5;
    }
}

// .none {
//     display: none;
// }

// .flex {
//     display: flex;

//     &.fill {
//         justify-content: unset;
//     }
// }

// .textarea,
// .select,
// .input {
//     display: block;
//     width: 100%;
//     padding: 0.375rem 0.75rem;
//     line-height: 1.5;
//     color: #000;
//     background-color: #fff;
//     background-clip: padding-box;
//     transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
//     display: block;
//     width: 100%;
//     padding: 10px;
//     margin: 10px 0;
//     border: 1.5px solid #000;
//     border-radius: 4px;
//     font-size: 13px;
//     font-weight: 500;
// }

// .sm-pas {
//     max-width: 220px;
// }

// .child.large {
//     width: 800px !important;
//     max-width: 80vw !important;
// }

// .grid-50 {
//     display: grid;
//     grid-template-columns: 1fr 1fr;
// }

// .gap {
//     gap: 10px;
// }

// .card-sms.dark {
//     padding: 3px;
//     background-color: #fafafa;
//     border-color: #dadada;
// }


// .bd {
//     padding: 10px;
//     border: 1px solid #dadada;
//     border-radius: 4px;

//     .head-grid {
//         display: grid;
//         grid-template-columns: auto auto;
//         justify-items: center;
//         justify-content: space-between;
//     }
// }

// label {
//     width: 100%;
// }



// input[type=number]::-webkit-outer-spin-button,
// input[type=number]::-webkit-inner-spin-button {
//     -webkit-appearance: none;
//     margin: 0;
// }





// body::-webkit-scrollbar {
//     width: 15px;
//     height: 30px;
// }

// body::-webkit-scrollbar-track {
//     background: #f1f1f1;
// }

// body::-webkit-scrollbar-thumb {
//     background: #888;
// }

// body::-webkit-scrollbar-thumb:hover {
//     background: #555;
// }

// .btn.main.sm {
//     width: auto;
//     margin: 0 auto;
//     text-transform: capitalize;
// }

// .style {
//     font-family: Arial, Helvetica, sans-serif;
//     min-height: 100dvh;
//     min-width: 100vw;
//     display: grid;
//     justify-content: center;
//     align-items: center;
//     text-align: center;
//     align-content: center;

//     svg {
//         max-width: 50%;
//         margin: 0 auto;
//         padding: 40px 0;
//     }
// }

// ul.nav li a.active {
//     background-color: $blue;
//     border-color: $blue;
//     color: white;
// }

// p.btn-lg {
//     display: block;
// }

.grid.pricing {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.max-form {
    margin-top: 20px !important;
    max-width: 290px;
    display: flex;
    gap: 5px;
    align-items: flex-end;
    margin: 0 auto;
}

.na {
    font-size: 13px;
    font-weight: normal;
    line-height: 1;
    padding: 4px 5px;
    background: #f1f1f1;
    border-radius: 3px;
    color: #727272;
    &.mail {
        padding:  0 5px !important;
    }
}