.popup {
  position: absolute;
  width: 100vw;
  height: 100%;
  top: 0;
  right: 0;
  left: 0;
  display: grid;
  justify-content: center;
  align-items: baseline;
  background-color: #00000050;
  z-index: 1;

  >.child {

    header {
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;

      select {
        margin: 0 !important;
        padding: 0 6px;

      }

      small {
        color: #ffffff;
        background: #4c63f0;
        border-radius: 5px;
        padding: 2px 10px;
        font-weight: 400;
        font-size: 12px;

        &.solid {
          background-color: #51b574 !important;
          color: #fff !important;
          line-height: 1;
          text-decoration: unset !important;
          padding: 4px 8px;
          margin: 2px 7px;
          font-weight: 500;
          text-transform: capitalize;
        }
      }

      span {
        padding: 10px;
        margin-right: -10px;
        line-height: 1;
        font-size: 20px;
        color: #555;
        margin-top: -4px;
        cursor: pointer;

        &:hover {
          color: #000;
        }
      }
    }

    >span {
      border-radius: 5px;

      text-transform: capitalize;
      padding: 10px;
      position: sticky;
      right: 0 !important;
      top: 0 !important;
      background: #dadada;
      line-height: 1;
      cursor: pointer;
      font-weight: bold;
      left: unset;
      float: right;
      z-index: 1;
      max-width: 30px;
      margin-left: auto;
    }

    // padding: 10px 20px 60px;
    max-width: 80vw;
    position: relative;
    top: 10px;
    z-index: 1;
    color: #000;
    margin: 0 auto;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 0.3rem;
    outline: 0;
    width: 500px;
    overflow: auto;
    position: sticky;
    top: 120px;

    &.wide {
      width: 600px;
    }

    max-height: 90dvh;

    header {
      padding: 10px 20px;
      border-bottom: 1px solid #dadada;
    }

    >section {
      padding: 25px 20px;
    }

    footer {
      padding: 10px 20px;
      border-top: 1px solid #dadada;
      text-align: right;
    }
  }

  .popup {
    z-index: 10 !important;

    >.child {
      z-index: 11 !important;
      max-height: 80dvh;

      >span {
        z-index: 12 !important;
      }
    }

    &.newgst {
      width: unset !important;
    }
  }
}



.POS-fees {
  margin: 4px 0px 4px 10px;
  font-size: 12px;
}

div.qr-wrapper {
  height: auto;
  display: grid;
  justify-content: center;
  align-items: center;
  width: 100%;

  canvas {
    width: 100%;
    height: auto;
    margin: 0 auto;
    max-width: 80%;
  }
}


h4 .Stripe,
h4 .nomod,
h4 button.pgw {
  background-color: transparent !important;
  border: unset;
  padding: 7px;
  cursor: pointer !important;
  display: inline !important;
  font-weight: bold;
  line-height: 1;
  font-size: 16px;

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed !important;
  }
}

.Stripe {
  color: #635bff;

  &:hover {
    color: #361ce7;
  }
}

.nomod {
  color: #111111;

  &:hover {
    color: #000000;
  }
}

.bookin input.sm {
  max-width: 100px;
}


.btn.danger {
  background-color: #ff8686;
}

.btn.save,
.btn.delete {
  width: 40px;
}

.btn.delete {
  background-color: #ff8686;

}

.btn.save {
  background-color: #dffabb;
}

center {
  text-align: center;
}

footer.center {
  text-align: center;
  margin-bottom: 5px;
}

.bookin {
  display: grid;
  grid-auto-flow: column;
  padding: 4px 10px;
  gap: 13px !important;
  margin: 4px 0;
  opacity: 1;
  // border: 1px solid #dadada;

  &.no-h {
    transition: 0.5s;
    opacity: 0;
    height: 0;
    padding: 0;
    margin: 0;

    * {
      width: 0;
      height: 0;
      padding: 0;
      margin: 0;
    }
  }

  &.whole {
    grid-template-columns: auto 1fr auto;
    justify-content: center;
    align-items: center;

    select {
      margin-bottom: 0 !important;
    }
  }
}

.accordion-main {
  .btn-grb.mn {
    .btn {
      margin: 0 3px;
    }
  }

  .bookin {
    grid-gap: 13px !important;
    display: grid;
    gap: 13px !important;
    grid-auto-flow: column;
    margin: 4px 0;
    opacity: 1;
    padding: 4px 10px;

    input {
      width: 100%;
      max-width: 100%;
    }
  }
}

.newGuestBtn {
  text-align: center;

  >div {
    color: #5d4cf0;
    font-size: 19px;
    font-weight: bold;
    text-transform: capitalize;
    text-decoration: underline;
  }
}

.card.dark.flex {

  display: block;
  border: 1px solid #dadada;
  padding: 8px 25px;
  margin: 10px 1px;
  border-radius: 4px;
}

.react-daterange-picker.form-control>.react-daterange-picker__wrapper {
  max-height: 36px;
}

.v-card {
  display: block !important;

  input {
    padding: 4px;
    margin: 4px;
  }

  text-align: center;
}

.notes {

  padding: 7px 27px 7px 7px;
  background: #dadada30;
  font-size: 14px;
  line-height: 1;
  margin-bottom: 6px;
  border-radius: 4px;
  border: 1px solid #dadada60;
  position: relative;

  .textArea {
    position: absolute;
    right: 3px;
  }
}

@media screen and (max-width:500px) {
  .accordion-main .bookin {

    display: grid;
    grid-template-rows: 1fr 1fr;
  }

  .flex3 {
    display: block !important;

    p.main {
      margin-bottom: 4px;
    }
  }

  .popup>.child {
    max-width: 97vw !important;
  }
}

// fix the order 
.popup.stripe {
  z-index: 2;
}