@import './colors.scss';

.login--container {
    max-width: 500px;
    margin: 0 auto;
    display: grid;
    justify-content: center;
    align-items: center;
    min-height: 100dvh;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;

    >form {
        position: relative;
        z-index: 1;
        border: 1px solid #0d47a12e;
        border-radius: 4px;
        background-color: #fff;

        label {
            text-transform: capitalize;
        }

        button {
            margin: 14px auto 0;
            display: block;
            padding: 10px 20px;
            background: #0f87ff;
            border: 1px solid #dadada;
            color: #fff;
            font-weight: bold;
            cursor: pointer;
            letter-spacing: 1px;
        }
    }

    .card {
        padding: 40px;

    }

    button {
        margin: 0;
        margin-top: 20px;
        border-radius: 4px !important;
        padding: 10px;
        transition: 0.5s;

        &:focus,
        &:hover,
        &:active {
            background-color: #000 !important;
        }
    }
}