.side-popup {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100%;
  right: 0;
  left: 0;
  display: grid;
  justify-content: flex-end;
  align-items: stretch;
  background-color: #00000050;
  z-index: 1;

  >.child {

    .flex.hd {
      grid-template-columns: 1fr auto !important;
    }

    header {
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: center;

      select {
        margin: 0 !important;
        padding: 0 6px;

      }

      small {
        color: #ffffff;
        background: #4c63f0;
        border-radius: 5px;
        padding: 2px 10px;
        font-weight: 400;
        font-size: 12px;

        &.solid {
          background-color: #51b574 !important;
          color: #fff !important;
          line-height: 1;
          text-decoration: unset !important;
          padding: 4px 8px;
          margin: 2px 7px;
          font-weight: 500;
          text-transform: capitalize;
        }
      }

      span {
        padding: 10px;
        line-height: 1;
        font-size: 20px;
        color: #555;
        cursor: pointer;

        &:hover {
          color: #000;
        }
      }
    }

    >span {
      border-radius: 5px;

      text-transform: capitalize;
      padding: 10px;
      position: sticky;
      right: 0 !important;
      top: 0 !important;
      background: #dadada;
      line-height: 1;
      cursor: pointer;
      font-weight: bold;
      left: unset;
      float: right;
      z-index: 1;
      max-width: 30px;
      margin-left: auto;
    }

    max-width: 80vw;
    position: relative;
    z-index: 1;
    color: #000;
    margin: 0 auto;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 0.3rem;
    outline: 0;
    width: 500px;
    overflow: auto;

    &.wide {
      width: 600px;
      height: 100%;
    }

    height: 100dvh;

    header {
      padding: 10px 20px;
      border-bottom: 1px solid #dadada;
    }

    >section {
      padding: 25px 20px;
    }

    footer {
      padding: 10px 20px;
      border-top: 1px solid #dadada;
      text-align: right;
    }
  }

  .popup {
    z-index: 10 !important;

    >.child {
      z-index: 11 !important;
      max-height: 80dvh;

      >span {
        z-index: 12 !important;
      }
    }
  }
}

.noteWrapper {
  position: relative;

  div.wrp {
    position: absolute;
    right: 0;
  }

  textarea {
    padding: 9px;
    font-size: 14px;
    line-height: 1.1;
    min-height: 64px;
    margin: 6px 0;
    border-radius: 3px;
  }

  .save {
    color: #3acfc2 !important;
  }
}

.POS-fees {
  margin: 4px 0px 4px 10px;
  font-size: 12px;
}


.bookin input.sm {
  max-width: 100px;
}


.btn.danger {
  background-color: #ff8686;
}

.btn.save,
.btn.delete {
  width: 40px;
}

.btn.delete {
  background-color: #ff8686;

}

.btn.save {
  background-color: #dffabb;
}

center {
  text-align: center;
}

footer.center {
  text-align: center;
  margin-bottom: 5px;
}

.bookin {
  display: grid;
  grid-auto-flow: column;
  padding: 4px 10px;
  gap: 13px !important;
  margin: 4px 0;
  opacity: 1;
  // border: 1px solid #dadada;

  &.no-h {
    transition: 0.5s;
    opacity: 0;
    height: 0;
    padding: 0;
    margin: 0;

    * {
      width: 0;
      height: 0;
      padding: 0;
      margin: 0;
    }
  }

  &.whole {
    grid-template-columns: auto 1fr auto;
    justify-content: center;
    align-items: center;

    select {
      margin-bottom: 0 !important;
    }
  }
}

.accordion-main {
  .btn-grb.mn {
    .btn {
      margin: 0 3px;
    }
  }

  .bookin {
    grid-gap: 13px !important;
    display: grid;
    gap: 13px !important;
    grid-auto-flow: column;
    margin: 4px 0;
    opacity: 1;
    padding: 4px 10px;

    input {
      width: 100%;
      max-width: 100%;
    }
  }
}

.newGuestBtn {
  text-align: center;

  >div {
    color: #5d4cf0;
    font-size: 19px;
    font-weight: bold;
    text-transform: capitalize;
    text-decoration: underline;
  }
}

.card.dark.flex {

  display: block;
  border: 1px solid #dadada;
  padding: 8px 25px;
  margin: 10px 1px;
  border-radius: 4px;
}

.react-daterange-picker.form-control>.react-daterange-picker__wrapper {
  max-height: 36px;
}

.v-card {
  display: block !important;

  input {
    padding: 4px;
    margin: 4px;
  }

  text-align: center;
}

.notes {

  padding: 7px 27px 7px 7px;
  background: #dadada30;
  font-size: 14px;
  line-height: 1;
  margin-bottom: 6px;
  border-radius: 4px;
  border: 1px solid #dadada60;
  position: relative;

  .textArea {
    position: absolute;
    right: 3px;
  }
}

@media screen and (max-width:500px) {
  .accordion-main .bookin {

    display: grid;
    grid-template-rows: 1fr 1fr;
  }

  .flex3 {
    display: block !important;

    p.main {
      margin-bottom: 4px;
    }
  }

  .popup>.child {
    max-width: 97vw !important;
  }
}

// fix the order 
.popup.stripe {
  z-index: 2;
}

.tab-content .content {
  display: none;
  padding: 20px 10px;
  margin-top: 10px;

  hr {
    margin: 20px 0px 10px;
    opacity: 0.3;
  }

  h3 {
    margin-bottom: 15px;

  }

  .flex {
    margin-bottom: 10px;

    &.footer {
      padding: 10px;
      background: #dadada50;

      .red {
        color: red;
      }
    }
  }
}

.Discounted {
  font-size: 12px;
  font-weight: 400;
  background: #cafcca;
  padding: 3px 8px;
  color: #0d8307;
  line-height: 1;
  border-radius: 4px;
}

.spn {
  font-size: 12px;
  font-weight: 400;
  background: #e5e5e5;
  padding: 3px 8px;
  color: #777777;
  line-height: 1;
  border-radius: 4px;
}

.tab-content .content:not(.hidden) {
  display: block;
}

.flex-select {
  display: grid;
  align-items: center;
  gap: 5px;
  grid-template-columns: 74px 155px 1fr;
  margin-bottom: 10px;

  .drop_save {
    padding: 0;
    margin: 0;
    width: 100%;
  }

  button,
  select {
    height: 23px;
    padding: 1px 9px;
    font-size: 13px;
  }

  button {
    border-radius: 0 3px 3px 0 !important;
  }

  select {
    border-radius: 3px 0 0 3px !important;
  }
}