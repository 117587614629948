.t-grid {
    background: #fff;
    color: #000;
    overflow: auto;

    .dates {
        display: flex;
        white-space: nowrap;
    }

    .head--Date {
        display: grid;
        justify-content: center;
        align-items: center;
        text-align: center;
        min-height: 55px;
        align-content: center;
        min-width: 90px;

        span {
            text-transform: capitalize;
        }

        b {
            font-size: 13px;
            color: #fff;
            background: #6d5ff0;
            padding: 4px;
            margin-top: 2px;
            font-weight: 500;
            line-height: 1;
            border-radius: 4px;
        }

        &.today {
            background: #d1e3ff;
        }
    }

    div.rooms>div:nth-child(1) {
        min-height: 55px;

    }

    .rooms {
        text-align: center;
        z-index: 1;
        position: sticky;
        left: 0;

        .rom {
            background-color: #fff;
            text-transform: capitalize;
            font-weight: bold;
            color: #555;
            height: 24px;
            white-space: nowrap;
            padding: 1px 8px;
            font-size: 14px;
            text-align: end;

            &:nth-child(even) {
                background-color: #dcdcdc;
                // color: #fff;
            }

        }

        .rom.last {
            height: 26px;
            padding-bottom: 2px;
        }
    }

    .wrap {
        background-color: #dadada50;
        padding-top: 27px;

        >div {
            background-color: #fff;
        }
    }

    .room-container {
        text-align: center;
        height: 24px;
        background-color: #dadada10;

        // &.first {
        // height: 28px;
        // margin-top: 27px;
        // }

        &.last {
            height: 28px;
        }

        [bookingstatus="waitlist"] {
            display: none;
        }
    }

    .rooms>div,
    .head--Date,
    .room-container>div {
        text-transform: lowercase;
        border: 1px solid #dadada50;
        line-height: 22px;

    }

    .last {
        border-bottom: 4px solid #f9a5a5 !important;
    }
}

#updateShow {
    opacity: 0;
}

#updateShow+.t-grid {
    .noShow {
        display: none;
    }

    .waitlist {
        display: none;
    }
}

.dateSelect {
    justify-content: end;
    margin: 50px 20px 10px 10px;
    gap: 20px;
    align-items: center;
    background: #fafafa;
    padding: 9px;
    display: inline-flex;
    border: 1px solid #dadada70;
    border-radius: 5px;

    label {
        width: auto;

        input {
            background-color: #fff;
            border-radius: 4px;
            border: 0px solid;
            padding: 2px 6px;
            opacity: 0.9;
            margin: 0 0 0 6px;
        }
    }
}

label.updateShow {
    text-transform: capitalize;
    padding: 2px 6px;
    cursor: pointer;
    display: inline-block
}

#updateShow:checked+.t-grid {
    .noShow {
        display: inline;
    }
}


#showPrice {
    opacity: 0;
    padding: 0;
    margin: 0;
    width: 0;
    height: 0;
}

span.name {
    user-select: none;
}

#showPrice~.t-grid {
    span.price {
        display: none;
    }
}

#showPrice:checked~.t-grid {
    span.price {
        display: block !important;
    }

    span.name {
        display: none !important;
    }
}


.t-grid {

    display: grid;
    grid-template-columns: auto 1fr;

}

.room-title {
    display: block;
    height: 25px;
    text-align: start;
    text-transform: capitalize;
    background-color: #fff;
    white-space: pre;
    font-size: 14px;
    padding: 3px;
}

.tableFooter {
    padding: 10px;
    background-color: #fff;
    text-align: center;
}

.main-table.header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;

    .left {
        padding: 10px;
    }
}

.color_b {
    display: inline-block;
    position: relative;
    padding: 0 15px 6px 15px;
    text-transform: capitalize;

    &::before {
        content: "";
        width: 10px;
        height: 10px;
        top: 4px;
        border-radius: 50%;
        background-color: red;
        position: absolute;
        left: 0;
    }
}

.color_not {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.88);
    font-size: 12px;
    line-height: 20px;
    list-style: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    display: inline-block;
    height: auto;
    margin-inline-end: 8px;
    padding-inline: 7px;
    white-space: nowrap;
    background: #fafafa;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    opacity: 1;
    transition: all 0.2s;
    text-align: start;
    position: relative;
}

.transfer.e_statue,
.transfer::before,
[bookingstatus="transfer"] {
    background-color: #a64ff8 !important
}

.authorized.e_statue,
.authorized::before,
[bookingstatus="authorized"] {
    background-color: #ffb350 !important
}

.checkIn.e_statue,
.checkIn::before,
[bookingstatus="checkIn"] {
    background-color: #25d451 !important
}

.Website.e_statue,
.website::before,
[bookingstatus="Website"],
[bookingstatus="website"] {
    background-color: #aed425 !important
}

.paid.e_statue,
.paid::before,
[bookingstatus="paid"] {
    background-color: #5ccbf5 !important
}

.checkInPaid.e_statue,
.checkInPaid::before,
[bookingstatus="checkInPaid"][ispaid="False"],
[bookingstatus="checkInPaid"][ispaid="True"] {
    background-color: #6ac189 !important;
}

.reserved.e_statue,
.noShow.e_statue,
.init.e_statue,
.reserved::before,
.noShow::before,
.init::before,
[bookingstatus="init"],
[bookingstatus="noShow"],
[bookingstatus="reserved"] {
    background-color: #d3d5d5 !important
}

// .e_statue.transfer,
.color_not.transfer {
    color: #7230af;
    background: #e2d0f3;
    border-color: #c7afdd;
}

// .e_statue.authorized,
.color_not.authorized {
    color: #a27131;
    background: #fff0dc;
    border-color: #ebcfab;
}

// .e_statue.checkIn,
.color_not.checkIn {
    color: #25d451;
    background: #deffe7;
    border-color: #c5e8ce;
}

// .e_statue.website ,
.color_not.website {
    color: #7e991e;
    background: #ecf6c8;
    border-color: #c6dd74;
}

// .e_statue.paid ,
.color_not.paid {
    color: #38768d;
    background: #e8f9ff;
    border-color: #a2ddf3;
}

// .e_statue.checkInPaid ,
.color_not.checkInPaid {
    color: #389e0d;
    background: #f6ffed;
    border-color: #b7eb8f;
}

.color_not.reserved,
.color_not.noShow,
.color_not.init {
    color: #464646;
    background: #fafafa;
    border-color: #d4d4d4;
}

[bookingstatus="noShow"] {
    display: none;
}

[platform]::before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 18px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 77%;
    background-color: #ffffff9c;
    padding: 1px;
    border-radius: 5px;
    margin-left: 2px;
}

[platform="Agoda"]::before { background-image: url('.././../assets/channel-icons/agoda.svg'); }
[platform="Airbnb"]::before { background-image: url('.././../assets/channel-icons/airbnb.svg'); }
[platform="Booking"]::before { background-image: url('.././../assets/channel-icons/booking.svg'); }
[platform="Expedia"]::before { background-image: url('.././../assets/channel-icons/expedia.svg'); }
[platform="Extension"]::before { background-image: url('.././../assets/channel-icons/extension.svg'); }
[platform="GoogleHotelARI"]::before { background-image: url('.././../assets/channel-icons/google.svg'); }
[platform="HostelWorld"]::before,
[platform="Hostelworld"]::before { background-image: url('.././../assets/channel-icons/hostelworld.svg'); }
[platform="Other"]::before { background-image: url('.././../assets/channel-icons/other.svg'); }
[platform="Referral"]::before { background-image: url('.././../assets/channel-icons/referral.svg'); }
[platform="WalkIn"]::before { background-image: url('.././../assets/channel-icons/walkIn.svg'); }
[platform="Website"]::before { background-image: url('.././../assets/channel-icons/website.svg'); }

[bookingstatus][i-id] {
    color: #fff;
    border-left: unset !important;
    border-radius: unset !important;
    width: calc(100% + 2px);
    font-size: 14px;
    padding: 0 4px;
    margin-left: -1px;
    text-align: start;
    text-transform: capitalize;
    position: relative;
    &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        left: 0;
        right: 0;
        bottom: -2px;
    }

    &.ist {
        margin-left: 0px;
        display: flex;
        padding: 0;
        align-items: center;
        gap: 5px;
    }
}


[r-id][r-id] {
    min-height: 24px;
    width: calc(100% + 2px);
    display: flex;
    gap: 1px;
    &:hover {
        cursor: pointer;
        background-color: #e9e9e9;
    }
}

.flex-one {
    display: flex;
    align-items: center;
    align-self: center;
    align-content: center;
    gap: 10px;
    margin-bottom: 10px;
}

.channel-icon-sm {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;

    >img {
        width: 24px;
        height: 24px;
        object-fit: contain;
    }
}

.active[href] {
    background: rgba(24, 119, 242, 0.08);
    border-radius: 4px;
    color: rgb(78 134 206);
}