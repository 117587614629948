.react-date-picker__wrapper {
    padding: 0;
    margin: 0;
    background-color: transparent !important;
    border: unset !important;

    .react-date-picker__calendar-button.react-date-picker__button,
    .react-date-picker__inputGroup {
        background-color: #fff;
        padding: 7px;
        border: 1px solid #dadada;
        border-radius: 5px;
        font-size: 16px;

        input {
            padding: 0;
            margin: 0 !important;
        }

    }

    .react-date-picker__calendar-button.react-date-picker__button {
        border-radius: 0 5px 5px 0;
        border-left: 0px !important;
    }

    .react-date-picker__inputGroup {
        border-radius: 5px 0 0 5px;
        border-right: 0px !important;
    }

    >.react-date-picker__clear-button.react-date-picker__button {
        display: none;
    }
}

.react-daterange-picker__clear-button.react-daterange-picker__button,
.react-daterange-picker__calendar-button.react-daterange-picker__button {
    display: none;
}


.react-daterange-picker.form-control {
    padding: 0;

    >.react-daterange-picker__wrapper {
        padding: .375rem .75rem;
        border: unset;

    }
}


.flex.genPrice {
    label {
        .react-daterange-picker.form-control {

            border-radius: 5px 0 0 5px;
        }
    }
}


// new datepicke
.datePicker {
    display: flex;

    input[type="date"] {
        margin: 0 !important;
        border-radius: 0px;
        border-right: unset;
    }
}

@media screen and (max-width:600px) {
    .datePicker {
        display: grid;

        input[type="date"] {
            border-right: 1px solid #dadada;
            border-bottom: unset;
        }
    }

    .flex.genPrice {
        grid-template-columns: 1fr;

    }

    .flex.genPrice p.btn {
        border-radius: 0 0 5px 5px;
    }

    .popup.adnew {
        .flex {
            display: grid !important;
            grid-template-columns: 1fr;
        }
    }

    .popup>.child header small.solid {
        white-space: pre;
    }
}