.head-flex {
    display: grid;
    grid-template-columns: 190px auto;
    justify-content: end;
    gap: 15px;
}

.header-menu {
    display: grid;
    grid-template-columns: auto 1fr;
    justify-content: center;
    align-items: center;
    padding: 10px 20px !important;
    background: #dadada30;
    border-bottom: 1px solid #dadada60;

    a,
    span {
        cursor: pointer;
        padding: 10px 22px;
        color: #555;
        text-decoration: none;
        display: block;
        white-space: nowrap;
    }

    ul {
        justify-content: end;
        align-content: flex-end;
        text-align: center;
        padding: 0;
        margin: 0;
        display: flex;

        >a,
        >span {
            border-radius: 5px;
            color: #555;
        }

        :hover {

            >a,
            >span {
                background-color: #dadada40;
                color: #0068c4;
            }

        }

        li {
            display: block;
        }
    }

    img {
        max-width: 120px;
        width: 100%;
        max-height: 38px;
    }

    .listholder {
        position: relative;

        ul {
            display: none;
            position: absolute;
            z-index: 1;
            right: 0;
            min-width: 10rem;
            padding: 0.5rem 0;
            margin: 0.125rem 0 0;
            color: #212529;
            text-align: left;
            list-style: none;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid rgba(0, 0, 0, .15);
            border-radius: 0.25rem;

            li {
                border-bottom: #dadada30;
                min-width: 50px;
                line-height: 1;
                font-size: 15px;
                text-align: start;

                &:hover {
                    background-color: #dadada15;
                }
            }
        }
    }

}

.header {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 10;
    background: #f1f1f1;
}

.input-menu {
    display: none;
    text-align: end;
    font-size: 20px;
    cursor: pointer;

    .active {
        display: block;

    }
}

.listholder:hover {

    ul {
        display: grid;
        position: absolute !important;
        justify-content: normal;
    }
}

@media screen and (max-width:890px) {



    .input-menu {
        display: none;
        text-align: end;
        font-size: 20px;
        cursor: pointer;
    }

    .header-menu {
        gap: 20px 0;

    }

    .input-menu {
        display: block;
    }

    .head-flex {
        grid-row: 2;
        grid-column: 1 / 3;
        grid-template-columns: 1fr;

        &.active {
            >ul {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                gap: 10px;

            }
        }

    }

    .header-menu ul {
        grid-column: 1 / 3;
        display: none;
    }


}